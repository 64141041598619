import { getTrackerConfigApi, deleteTrackerConfigApi, saveTrackerConfigApi, editTrackerConfigApi, getTrackerStatusApi } from '../service/partnerIntegrationService';
import {apiError, apiRequest, apiSuccess} from '../../app/actions/appAction';
import {setData} from '../../helper/action/actionsUtil';
import {TRACKER_CONFIG_LIST, RESET_CURRENT_CONFIG} from '../../constants/trackersConstants';

export const fetchTrackerConfigAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    clearTrackerConfigListAction(actionData)(dispatch); //clear previously stored data before fetching new data
    dispatch(apiRequest(storePath));
    const isDCM = actionData.requestInfo && actionData.requestInfo.isDCM ?  true : false;
    getTrackerConfigApi( actionData.url, isDCM || false, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
			if(isDCM)
				dispatch(setData(response.configList, actionData.actionType));
			else
            	dispatch(setData(response.configurations, actionData.actionType));
        }
    });
};

export const clearTrackerConfigListAction = (actionData) => (dispatch) => {
    dispatch(setData([], actionData.actionType))
}

export const setEditTrackerConfig = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    const data = {
        ...actionData.configuration,
        formAction: actionData.formAction
    }
    dispatch(setData(data, actionData.actionType));
}

// FixMe: Redundant function!
// TODO: Rename setEditTrackerConfig and use it for the both edit and view cases
export const setViewTrackerConfig = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    const data = {
        ...actionData.configuration,
        formAction: actionData.formAction
    }
    dispatch(setData(data, actionData.actionType));
}

export const saveTrackerConfigAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    dispatch(apiRequest(storePath));
    saveTrackerConfigApi( actionData.url, actionData.postData, (response) => {
        handleResponse(actionData, response, dispatch)
    });
};

export const editTrackerConfigAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    dispatch(apiRequest(storePath));
    editTrackerConfigApi( actionData.url, actionData.postData, (response) => {
        handleResponse(actionData, response, dispatch)
    });
};

const handleResponse = (actionData, response, dispatch) => {
    if(response.error) {
        dispatch(apiError(response.error, actionData.storePath));
    } else {
        const isDCM = actionData.postData.isTemplateAccount && actionData.postData && actionData.postData.isDCM ? true : false;
        const fetchActionData = {
            storePath: TRACKER_CONFIG_LIST,
            actionType: TRACKER_CONFIG_LIST,
			requestInfo: {isDCM: isDCM},
            url: actionData.requestInfo.endPoint
        }
        fetchTrackerConfigAction(fetchActionData)(dispatch);
        dispatch(apiSuccess(actionData.storePath, true, true));
    }
}

export const deleteTrackerConfigAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    dispatch(apiRequest(storePath));
    const isDCM = actionData.requestInfo && actionData.requestInfo.isDCM ? true : false;
    deleteTrackerConfigApi( actionData.url, isDCM, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(setData(actionData.requestInfo, actionData.actionType));
        }
    });
};

export const resetCurrentConfigAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    dispatch({type: RESET_CURRENT_CONFIG});
}

export const trackerStatusCheckAction = (actionData) => (dispatch) => {
    let storePath = actionData.storePath;
    dispatch(apiRequest(storePath));
    getTrackerStatusApi( actionData.url, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(setData(response.allTrackers, actionData.actionType));
        }
    });
};
